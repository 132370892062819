import axios from "axios";
import { toast } from "react-toastify";
// import { URL_api } from "../../utility/util";

// Set config defaults when creating the axiosOnehcm
const axiosOnehcm = axios.create({
  // baseURL: 'https://api.example.com'
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // Accept: "application/json, text/plain, */*",
    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Expose-Headers": "*",
  },
});
axiosOnehcm.defaults.baseURL = "https://frontend.40.119.238.74.nip.io/";

axiosOnehcm.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosOnehcm.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    // console.log("response", response);
    if (response && response.status === 401) {
      // localStorage.removeItem('userInfo')
      // localStorage.removeItem('allEnumOption')
      // localStorage.removeItem('allGlobalMasterOption')
      // localStorage.setItem("tokenExpired", true);
      localStorage.clear();
      window.location.href = "/";
    } else {
      toast.error(response.status + " - " + response.data.detail);
    }
    return Promise.reject(error);
  }
);

export default axiosOnehcm;
